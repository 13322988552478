<template>
  <div class="com-container">
    <h3 class="chart-map" @click="backToMapLevel()" >各行政区白蚁监测情况 </h3>
    <div class="com-chart" id="map"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  data () {
    return {
      webData: null,
      // mapLevel: [], // 当前显示地图的级别和区域['Region', '杭州']
      geoJsonFile: '', // 示例： 'https://6279-bytube119-1g0lt4yo8ba6465c-1305609995.tcb.qcloud.la/WebApp/china/Zhejiang/Hangzhou/%E5%BB%BA%E5%BE%B7%E5%B8%82.geojson?sign=e8c54950f4a5b88cd9081ab13a3ae359&t=1723297751'
      geoCenter: [], // 当前显示的地图的中心点 示例： [29.330978, 119.093764]
      TMapGeoCenter: [],
      geoCoordMap: {}, // 地图中心的纬度经度映射，示例：{ 杭州: [30, 120], 金华: [29, 120.55555]}
      nameMap: {}, // 区域地名的缩写映射，示例： { 杭州市: '杭州', 金华市: '金华', 丽水市: '丽水'}
      areaData: [] // 左2行政区域展示数据，示例：[{ name: '杭州', value: 34 },{ name: '金华', value: 0 }]
    }
  },
  // computed: {
  //   // 根据mapLevel 得到响应式的 geoJsonUrl areaData nameMap geoCoordMap 等行政地图需要的数据
  //   geoJsonUrl: function () {
  //     return 1
  //   }
  // },
  mounted () {
    // this.mapLevel = ['Province', '浙江']
    // this.geoJsonUrl = 'https://6279-bytube119-1g0lt4yo8ba6465c-1305609995.tcb.qcloud.la/WebApp/geoJson/zhejiang/zhejiang.geojson?sign=d4f7ed9ebc95fcb2a7395421337c0aa2&t=1723258744'
    // this.areaData = [
    //   { name: '杭州', value: 34 },
    //   { name: '金华', value: 0 },
    //   { name: '丽水', value: 0 },
    //   { name: '台州', value: 10 }]
    // this.nameMap = {
    //   杭州市: '杭州',
    //   金华市: '金华',
    //   丽水市: '丽水',
    //   台州市: '台州'
    // }
    // this.geoCoordMap = {
    //   杭州: [30, 120],
    //   金华: [29, 120.55555]
    // }
    // this.TMapGeoCenter = [30, 120]

    // this.mapLevel = ['Region', '杭州']
    // this.geoJsonUrl = 'https://6279-bytube119-1g0lt4yo8ba6465c-1305609995.tcb.qcloud.la/WebApp/china/Zhejiang/Hangzhou.geojson?sign=327a4fa6602a5b5dcfd1ca1dc687d168&t=1723293974'
    // this.areaData = [
    //   { name: '建德', value: 3 },
    //   { name: '萧山', value: 8 },
    //   { name: '桐庐', value: 0 }]
    // this.nameMap = {
    //   建德市: '建德',
    //   萧山区: '萧山',
    //   桐庐县: '桐庐'
    // }
    // this.geoCoordMap = {
    //   杭州: [30, 120],
    //   金华: [29, 120.55555]
    // }
    // this.TMapGeoCenter = [30, 120]

    // this.mapLevel = ['City', '建德']
    // this.geoJsonUrl = 'https://6279-bytube119-1g0lt4yo8ba6465c-1305609995.tcb.qcloud.la/WebApp/china/Zhejiang/Hangzhou/%E5%BB%BA%E5%BE%B7%E5%B8%82.geojson?sign=e8c54950f4a5b88cd9081ab13a3ae359&t=1723297751'
    // this.areaData = [
    //   { name: '更楼', value: 2 },
    //   { name: '三都', value: 3 },
    //   { name: '寿昌', value: 0 }]
    // this.nameMap = {
    //   更楼街道: '更楼',
    //   三都镇: '三都',
    //   寿昌镇: '寿昌'
    // }
    // this.geoCoordMap = {
    //   更楼: [30.1, 120],
    //   三都: [29.1, 120.55555]
    // }
    // this.TMapGeoCenter = [30, 120]

    this.geoJsonUrl = this.$store.state.webData.geoJsonFile
    this.nameMap = this.$store.state.webData.nameMap
    // this.devInProj = this.$store.state.webData.devInProj
    // this.mapLevel = this.$store.state.webData.area.mapLevel

    this.areaData = this.$store.state.webData.mapChartData
    this.geoCoordMap = this.$store.state.webData.geoCoordMap
    // this.areaData = [
    //   { name: '杭州', value: 34 },
    //   { name: '金华', value: 0 },
    //   { name: '丽水', value: 0 },
    //   { name: '台州', value: 10 }]
    // this.geoCoordMap = {
    //   杭州: [30, 120],
    //   金华: [29, 120.55555]
    // }
    // this.geoJsonUrl_Region = this.$store.state.webData.webEcharts.geoJsonUrl_Region
    // this.nameMap_Region = this.$store.state.webData.webEcharts.nameMap_Region
    this.initChart()
  },
  methods: {
    // 初始化行政地图 级别 geoJson的url
    // mapLevel为数组[级别，地名] 例：['China','中国']['Province','浙江'] ['Region'，'金华']['City','金东']    China-中国 Province-省 Region-地区 City-县级市 Town-乡镇
    // initChart (mapLevel, geoJsonUrl, areaData, nameMap, geoCoordMap) {
    initChart () {
      var chartDom = document.getElementById('map')
      this.myChart = echarts.init(chartDom, 'dark')
      var option
      this.$axios({
        methods: 'GET',
        url: this.geoJsonUrl
      })
        .then(res => {
          echarts.registerMap('city', res.data)
          this.myChart.setOption(
            option = {
              tooltip: {
                trigger: 'item',
                formatter: '{b}<br/>蚁害 {c} 处'
              },
              visualMap: {
                type: 'continuous',
                // min: townMin,
                max: 3, // 显示为红色的值 最大值
                // max: areaMax,
                min: 0,
                // max: 3,
                // text: ['High', 'Low'],
                text: ['H', 'L'],
                realtime: false,
                calculable: true,
                bottom: '30%',
                itemWidth: '8px',
                inRange: {
                  color: ['lightskyblue', 'yellow', 'orangered']
                }
              },
              series: [
                {
                  name: '设备数',
                  type: 'map',
                  map: 'city', // 自定义扩展图表类型
                  zoom: 1.2,
                  label: {
                    show: true
                  },
                  data: this.areaData,
                  // 自定义名称映射
                  nameMap: this.nameMap
                }]
            })
          // this.myChart.showLoading()
          this.myChart.on('click', (params) => {
            // console.log(params.data.name, params.dataIndex)
            var areaName = params.data.name
            // 点击乡镇 地图切换到对应的中心点
            this.geoCenter = this.geoCoordMap[areaName] // 目前数据在页面内，待放到数据库中
            // console.log('this.geoCoordMap', this.geoCoordMap)
            // console.log('this.geoCenter', this.geoCenter)
            this.setGeoCenter(this.geoCenter)
          })

          option && this.myChart.setOption(option)
        })
    },
    // 函数：重新定义地图的中心点 参数：[经度,纬度] 例：[120.710868,30.297542]
    setGeoCenter: function (e) {
      this.$store.commit('newGeoCenter', e)
    }

  }
}
</script>

<style lang="less" scoped>
.chart-map {
  display: flex;
}
.select-map {
  flex: 3;
  color: rgba(14, 131, 131, 0.767);
  font-size: .175rem;
  text-align: right;
  right: .125rem;
  margin-left: .25rem;
    span{
      margin-left: .25rem;
    }
    a{
      // color: rgb(204, 18, 18);
      text-decoration: none;
      // margin: 0 0.125rem;
    }
}

</style>
