<template>
  <div class="com-page-index">
    <!-----------------------------------开场动画 ------------------------------------->
    <!-- <section class="animation" v-if="!endLoading && !isLoginCard"> -->
    <!-- <section class="animation" v-if="showAnimation">
      <div>
        <h2>
            <span v-for="(item,index) in '建德市水利局智慧白蚁系统'"
            <span v-for="(item,index) in webName" v-bind:key = "index">
                {{item}}
            </span>
        </h2>
        <img src="../assets/images/bg.jpg" alt="" v-if="showAnimation">
      </div> -->
    <!-- 维护单位显示 -->

    <!-- </section> -->
    <section class="animation" v-if="showAnimation">
        <div>
          <h2>
            <span
              v-for="(char, index) in webNameChars"
              :key="index"
              :style="{ animationDelay: getAnimationDelay(index) }"
            >
              {{ char }}
            </span>
          </h2>
          <img src="../assets/images/bg.jpg" alt="" v-if="showAnimation">
        </div>
      </section>

    <!-----------------------------------头部的盒子 ------------------------------------->
    <header v-if="endLoading">
      <!-- 背景时间 -->
      <TopBox></TopBox>

        <!-- 状态提示 -->
      <div class="current-num" v-if="endLoading">
          <div class="left-row1">
          <div id="cz" class="flash-placed"></div>
          <p>在线</p>
          <div id="dmt" class="flash-alerted"></div>
          <p>报警</p>
          <div id="dmt" class="flash-handled"></div>
          <p>处理</p>
        </div>
        <div class="left-row2">
          <p>{{total_placed}}</p>
          <p>{{total_alerted}}</p>
          <p>{{total_handled}}</p>
        </div>
      </div>

        <!-- 退出登陆 -->
      <div :class="btnStyle" @click="onLoginOut" @mouseenter="enter" @mouseleave="leave" >
        退出登陆
      </div>
    </header>

    <!------------------------------------ 登陆卡 部分 -------------------------------->
    <section class="login-box">
      <div class="index-icp">
        <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2021015476号-1</a>
      </div>
      <div v-if="isLoginScan">
        <LoginScan></LoginScan>
      </div>
      <div v-if="isLoginCard && !isLoginScan">
        <LoginCard></LoginCard>
      </div>
    </section>

    <!------------------------------------ 登陆中  部分 -------------------------------->
    <!-- <section class="mainbox" v-if="isLoading" > -->
<!--     <section class="mainbox" v-if="endLoading" >
      <div class="loadingbox">
          <div class="loading">
              <span>{{webName}}</span>
          </div>
      </div>
    </section> -->

    <!------------------------------------ 可视化页面  主体部分 -------------------------------->
    <section class="mainbox devicebox" v-if="endLoading">
      <!------ 主体1 左边栏 文件图片------>
      <div class="column" >
        <div class="panel profile">
        <!-- 项目简介 -->
            <Profile></Profile>
          <div class="panel-footer"></div>
        </div>

        <div class="panel map">
        <!-- 板块地图 -->
            <ChartMap></ChartMap>
          <div class="panel-footer"></div>
        </div>

        <div class="panel pie2">
        <!-- 各水库设备数量 -->
            <ChartPie></ChartPie>
          <div class="panel-footer"></div>
        </div>
      </div>

      <!------主体2 中间栏 ------->
      <div class="column" id="midvue" >
        <!-- 实时地图 -->
        <div class="tmap">
          <div class="div_any_child div_height">
            <div id="map_div">
            <!-- 腾讯地图模块 -->
              <TMap></TMap>

            </div>
          </div>
        </div>
        <!-- 设备表格 施工图片 -->
          <ChartTable></ChartTable>
      </div>

      <!-------主体3 右边栏 图表 ------->
      <div class="column">
        <div class="panel">
        <!-- 设备状态 -->
            <ChartLine></ChartLine>
          <div class="panel-footer"></div>
        </div>
        <div class="panel">
        <!-- 相片 -->
          <Photo></Photo>
          <div class="panel-footer"></div>
        </div>

        <div class="panel tab2">
          <Card></Card>
          <div class="panel-footer"></div>
        </div>
      </div>

    </section>
    <!------------------------ 主体4  详情页面，点击表格内容奇数次时 悬浮显示 -->
    <section>
      <deviceDetail></deviceDetail>
    </section>

  </div>
</template>

<script>
import TopBox from '@/components/TopBox' // 顶部模块 背景图加时间显示
import LoginCard from '@/components/LoginCard' // 密码登录模块
import LoginScan from '@/components/LoginScan' // 扫码登录模块
import QRCode from 'qrcode' // 引入生成二维码插件

import Profile from '@/components/Profile'
import ChartMap from '@/components/ChartMap'
import ChartPie from '@/components/ChartPie'
import TMap from '@/components/TMap'
import ChartLine from '@/components/ChartLine'
import Photo from '@/components/Photo'
import DeviceDetail from '@/components/DeviceDetail'
import ChartTable from '@/components/ChartTable'
import Card from '@/components/Card'

// 连接资源
import cloudbase from '@cloudbase/js-sdk'
const app = cloudbase.init({
  env: 'bytube119-1g0lt4yo8ba6465c'
})

export default {
  data () {
    return {
      showAnimation: true, // 是否显示开场动画
      isLoginCard: false, // 是否要登陆  当没有保存uid时为 true
      isLoading: false, // 正在登陆，得到网站名称后 显示动画 加载全局数据
      QRCodeMsg: '',
      area: [], // 行政区块
      shortName: [], // 饼图的数据，所有项目简称的数组
      total_placed: '',
      total_alerted: '',
      total_handled: '',
      endLoading: false, // 结束登陆，动画结束 且 完成加载全局数据 显示可视化内容
      btnStyle: 'hiddenBtn',
      uid: '',
      webName: '',
      webData: null,
      card: '',
      needReLogin: this.$store.state.needReLogin
    }
  },
  mounted () {
    // this.uid = this.$store.state.uid
    this.init()
  },
  computed: {
    isLoginScan () {
      return this.$store.state.isLoginScan
    },
    webNameChars () {
      // 将字符串转换为字符数组
      return this.webName.split('')
    }
  },
  methods: {
    // Login-start
    // 函数  ---------------------匿名登陆  获取uid 并生成二维码------------------------------
    init () {
      app
        .auth({
          persistence: 'local'
        }).anonymousAuthProvider().signIn()
        .then((item) => {
          // 登录成功
          // console.log('1-init匿名登录成功,返回uid:', item.user.uid)
          this.uid = item.user.uid
          // this.$store.state.uid = item.user.uid
          this.$store.commit('getUid', item.user.uid)
          this.loginByUid(item.user.uid) // 用uid尝试登陆
        })
        .catch((err) => {
          // 登录失败
          console.log('登录失败', err)
        })
    },

    // 函数 以uid为参数 相关的数据  uid绑定在绑定在webapp下
    loginByUid (newUid) {
      console.log('2-loginByUid 函数调用成功')
      const db = app.database()
      const _ = db.command
      db.collection('Web_App')
        .where({ uid: _.elemMatch(_.eq(newUid)) })
        .field({
          webName: true,
          card: true
        })
        .get()
        .then(res => {
          // console.log('2-loginByUid返回结果:', res)
          if (res.data.length > 0) { // 返回记录数大于0，有该用户名和密码下的web_app存在
            // console.log('2-loginByUid返回结果-网站名称:', res.data[0].webName)
            this.webName = res.data[0].webName
            // console.log('2-loginByUid返回结果－联系单:', res.data[0].card)

            this.isLoginCard = false
            // console.log('web有uid个数大于等于1 可登录')
            // this.$router.push('/globaldatapage')
            // this.$router.push('/index')
            this.isLoading = true
            this.showAnimation = true
            this.getData(newUid) // 获得全局数据 写入到vuex   判断数据不为空后载入展示首页
          } else {
            // console.log('loginByUid(uid)返回结果:uid小于1，不能登录')
            // uid未保存，初始化数据失败, 显示登陆卡 不显示开场动画 不显示可视化界面
            this.showAnimation = false
            this.endLoading = false
            this.isLoading = false
            this.$store.commit('needReLogin', false)
            // 显示登录卡
            this.isLoginCard = true
            // 显示登录卡时，网站名得上移，否则会挡主,使 css .needloginh2 或 .isloginh2 生效

            // this.isLoginScan = false
            this.$store.commit('changeLoginToScan', false)
            this.getQRCode(newUid)
            // this.watchUid() // 监听是否写入了uid（扫码成功），写入则跳转页面到展示页
          }
        })
    },

    // 函数 －－－－－－－－－－获取全局数据 并写入到vuex中－－－－－－－－－－－－－－－－－－－－－－－
    getData (uid) {
      app.callFunction({
        name: 'webLogin',
        data: {
          // $url: 'getWebData100', // 版本1.0.0适用
          $url: 'getWebData101', // 版本1.0.1适用 geo数据放入 Web_App_Geo 内
          uid: uid
        }
      }).then(res => {
        this.$store.state.webData = res.result.data // 写到vuex
        // 得到数据后 初始化数据成功, 显示可视化界面 不显示登陆卡 开场动画定时关闭
        this.isLoading = false
        this.endLoading = true
        setTimeout(() => {
          this.showAnimation = false
        }, 9000)
        // console.log('7、getData 返回store数据-----------710--', this.$store.state.webData)
      })
    },

    // 函数  生成二维码
    getQRCode (newUid) {
      const opts = {
        errorCorrectionLevel: 'H', // 容错级别
        type: 'image/png', // 生成的二维码类型
        quality: 0.3, // 二维码质量
        margin: 2, // 二维码留白边距
        width: 188, // 宽
        height: 188, // 高
        text: newUid, // 二维码内容
        color: {
          dark: '#9febe0', // 前景色
          light: '#34495d'// 背景色
        }
      }
      this.QRCodeMsg = newUid // 生成的二维码为新的浏览器uid
      const msg = document.getElementById('QRCode_header')
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, this.QRCodeMsg, opts, function (error) {
        console.log('5、getQRCode生成二维码', error)
      })
    },

    // Login---------------------end----------------
    // GlobalDataPage start

    onLoginOut: function () {
      // console.log('退出登陆')
      // console.log('退出登陆uid')
      this.unbindUid(this.uid)
    },
    enter: function () {
      // console.log('enter');
      this.btnStyle = 'showBtn'
    },
    leave: function () {
      // console.log('leave');
      this.btnStyle = 'hiddenBtn'
    },

    // 退出登陆
    unbindUid (uid) {
      app.callFunction({
        name: 'webLogin',
        data: {
          $url: 'unbindUid100',
          uid: uid
        }
      }).then(res => {
        // this.$router.push('/index')
        this.isLoginCard = true
        // this.$store.commit('needReLogin', true) // 传递true值 通知index需要重新登录
        // this.endLoading = false
        this.endLoading = false
        this.loginByUid(this.uid)
        // console.log('已经退出登陆:', res)
      })
    },
    // 函数 监听数据库 判断小程序扫码后 是否已经写成写入uid －存在新的uid，完成则跳转到加载数据页面
    watchDevice_Info () {
      // console.log('projectList', this.$store.state.webData.projectList)
      const db = app.database()
      const _ = db.command
      db.collection('Device_Info')
        .where({ project_id: _.in(this.$store.state.webData.projectList) })
        // .where({ project_id: _.in(['79550af260878b29116ae8df4076375c', 'cbddf0af60b97d4e0e1362eb6a328815']) })
        .watch({
          onChange: (snapshot) => {
            if (snapshot.msgType === 'INIT_EVENT') {
              // console.log('监控数据初始化成功', snapshot)
            } else if (snapshot.msgType === 'NEXT_EVENT') {
              // console.log('设备数据变化了', snapshot)
              this.reLogin(this.uid)
            } else {
              // console.log('设备数据变化了else', snapshot)
            }
          },
          onError: (error) => {
            console.log('远端数据库监听失败！', error)
          }
        })
    },
    // 函数 －－－－－－－－－－重新获取全局数据 刷新页面数据－－－－－－－－－－－－－－－－－－－－－－－
    reLogin (uid) {
      app.callFunction({
        name: 'webLogin',
        data: {
          $url: 'getWebData100', // 版本1.0.0适用
          uid: uid
        }
      }).then(res => {
        this.$store.state.webData = res.result.data // 写到vuex
        // console.log('数据刷新')
      })
    },
    // 开局动画
    getAnimationDelay (index) {
      // 根据索引计算动画延迟，这里每个字符延迟0.1秒
      return `${index * 0.1}s`
    }
  },
  updated () {
    this.getQRCode(this.uid)
  },
  watch: {
    '$store.state.webData' () {
      // this.endLoading = true
      this.total_placed = this.$store.state.webData.total_placed
      this.total_alerted = this.$store.state.webData.total_alerted
      this.total_handled = this.$store.state.webData.total_handled
      this.company = this.$store.state.webData.card.company
      this.name = this.$store.state.webData.card.name
      this.phone = this.$store.state.webData.card.phone
      this.watchDevice_Info()
    },
    // 如果needReLogin为真，重新调用登录函数 调
    '$store.state.needReLogin': function (val, oldval) {
      if (val === true) {
        // console.log('监视到needReLog为真，需要重新调用登录函数')
        this.loginByUid(this.uid)
      }
    }
  },
  // GlobalDataPage-----------end-----------------
  components: {
    TopBox,
    LoginCard,
    LoginScan,

    Profile,
    ChartMap,
    ChartPie,
    TMap,
    ChartTable,
    DeviceDetail,
    ChartLine,
    Photo,
    Card
  }
}
</script>

<style lang="less" scoped>
// 加载动画，global.less 中可删除
*{
  margin: 0;
  padding: 0;
  font-family: '微软雅黑', sans-serif;
}
.com-page-index {
  // line-height: 1.15;
  // background-size: 100% 100%;
  // color: #fff;
  width: 100%;
  // height: 12.8rem;
  height: 100%;
  position: relative;
  overflow: hidden;

  // 登陆部分
  .login-box{
    background: #000;
    // background: url(../assets/images/head0.png) no-repeat;
    .index-icp{
      position: absolute;
      /* position: absolute; */
      /* position: relative; */
      top: 95%;
      left: 50%;
      /* top: 0;
      left: 0; */
      transform: translate(-50%,-50%);
      a{
        text-decoration: none;
        color: #fff;
      }
    }
  }

  // 开场动画部分
  .animation {
    position: relative;
    width: 100%;
    // height: 12.8rem;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3000;
    // background: url(../assets/images/bg.jpg) no-repeat;
    // 平台
    h2{
      position: relative;
      // color: #105fcc;
      color: #00b3ff;
      font-size: 7em;
      display: flex;
      text-transform: uppercase;
      font-weight: 800;
      z-index: 3001;

      // animation: disappearh2 5s linear;
      // animation-delay: 8s;
      // transition: 6s;
      span{
        animation: animateh2 6s linear;
        // animation: animate 8s linear infinite;
      }
      // span:nth-child(1){
      //   animation-delay: 0s;
      // }
      // span:nth-child(2){
      //   animation-delay: 0.1s;
      // }
      // span:nth-child(3){
      //   animation-delay: 0.2s;
      // }
      // span:nth-child(4){
      //   animation-delay: 0.3s;
      // }
      // span:nth-child(5){
      //   animation-delay: 0.4s;
      // }
      // span:nth-child(6){
      //   animation-delay: 0.5s;
      // }
      // span:nth-child(7){
      //   animation-delay: 0.6s;
      // }
      // span:nth-child(8){
      //   animation-delay: 0.7s;
      // }
      // span:nth-child(9){
      //   animation-delay: 0.8s;
      // }
      // span:nth-child(10){
      //   animation-delay: 0.9s;
      // }
      // span:nth-child(11){
      //   animation-delay: 1s;
      // }
      // span:nth-child(12){
      //   animation-delay: 1.1s;
      // }
      // span:nth-child(13){
      //   animation-delay: 1.2s;
      // }
      // span:nth-child(14){
      //   animation-delay: 1.3s;
      // }
      // span:nth-child(15){
      //   animation-delay: 1.4s;
      // }
    }
    img {
      position: absolute;
      left: 0;
      bottom:0;
      display: block;
      outline: none;
      border:0;
      width: 100%;
      height: 12.8rem;
      opacity: 0;
      animation: animateimg 11s linear;
      // animation: animateimg 11s linear infinite;
    }
    // .disappear{
    //   // background: #00000000;
    //   animation: disappearh2 4s;
    //   animation-delay: 5s;
    // }
    // h2 img 动画
    @keyframes animateh2 {
      0%{
        color: #fff;
        filter: blur(2px);
        text-shadow: 0 0 10px #fff,
                  0 0 20px  #fff,
                  0 0 30px  #fff,
                  0 0 40px  #228DFF,
                  0 0 50px  #228DFF,
                  0 0 60px  #228DFF,
                  0 0 70px  #228DFF,
                  0 0 80px  #228DFF,
                  0 0 90px  #228DFF,
                  0 0 100px #228DFF,
                  0 0 110px  #228DFF,
                  0 0 120px  #228DFF,
                  0 0 130px  #228DFF,
                  0 0 140px  #228DFF,
                  0 0 150px #228DFF,
      }
  45%, 75%{
    color: rgba(0, 0, 0, 0);
    filter: blur(0px);
    text-shadow: none;
  }
  100%{
    color: rgba(0, 0, 0, 0);
      filter: blur(0px);
      text-shadow: none;
  }
    }
    @keyframes disappearh2 {
      0%, 20% {
          // filter: blur(0px);
          opacity: 1;
        }
      100% {
          // filter: blur(100px);
          opacity: 0;
        }
    }
    @keyframes animateimg {
      0%   {
          filter: blur(10px);
          transform:scale(2);
          opacity: 1;
        }
      10%{
          opacity: 1;
      }
      // 35% {
      //     filter: blur(0px);

      //     opacity: .8;
      //   }
      // 65% {
      //     filter: blur(0px);
      //     transform:scale(1);
      //   }
      // 80% {
      //     // filter: blur(30px);
      //     // transform:scale(2);
      //     opacity: 0;
      //   }
      100% {
          filter: blur(0px);
          transform:scale(1);
          opacity: 0;
        }
    }

    // 维护单位部分

  }

  // 头部
  header {
    // position: relative;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    // height: 1.25rem;
    height: 1.25rem;
    background: url(../assets/images/head0.png) no-repeat;
    // background: url(https://6279-bytube119-1g0lt4yo8ba6465c-1305609995.tcb.qcloud.la/WebApp/image/head-jd001.png?sign=084a47d34f1908866c3209a415e922f5&t=1629776939) no-repeat;
    background-size: 100% 100%;
    padding-top: .3rem;

    .current-num{
      width: 4rem;
      height: .75rem;
      color:#00d887;
      position: absolute;
      top: .2rem;
      left: .225rem;

      .left-row1{
        display: flex;
        p{
        font-size: .275rem;
        margin: .1rem .25rem 0 .20rem;
        }
      }
      .left-row2{
        display: flex;
        font-family: "electronicFont";
        p{
          flex: 1;
          font-size: .6rem;
          color: #ffeb7b;
          text-align: center;
          font-family: "electronicFont";
          }
      }

      .flash-placed{ position:relative; width:.25rem; height:.25rem; margin:.125rem auto; line-height:.5rem; border:1px solid #008000; border-radius:.125rem; color:#fff; font-size:.25rem; text-align:center; cursor:pointer; box-shadow:0 1px 2px rgba(0,0,0,.3); overflow:hidden;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#0000ffa9), to(#0000FFa9));
        background-image: -moz-linear-gradient(#1989fa,#1989fa);
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breathe;
        -webkit-animation-duration: 1000ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate;
        animation-timing-function: ease-in-out;
        animation-name: breathe;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation:ease-in-out breathe 1000ms infinite alternate;
      }
      .flash-alerted{ position:relative; width:.25rem; height:.25rem; margin:.125rem auto; line-height:.5rem; border:1px solid #008000; border-radius:.125rem; color:#fff; font-size:.25rem; text-align:center; cursor:pointer; box-shadow:0 1px 2px rgba(0,0,0,.3); overflow:hidden;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#FF0000), to(#FF0000));
        background-image: -moz-linear-gradient(#ee0a24,#ee0a24);
        background-image: gradient(linear, left top, left bottom, from(#FF0000), to(#FF0000));
        background-image: linear-gradient(#ee0a24,#ee0a24);
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-name: breathe;
        animation-name: breathe;
        -webkit-animation-duration: 3000ms;
        animation-duration: 3000ms;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-direction: alternate;
        animation-direction: alternate;
        animation:ease-in-out breathe 300ms infinite alternate;
      }
      .flash-handled{ position:relative; width:.25rem; height:.25rem; margin:.125rem auto; line-height:.5rem; border:1px solid #008000; border-radius:.125rem; color:#fff; font-size:.25rem; text-align:center; cursor:pointer; box-shadow:0 1px 2px rgba(0,0,0,.3); overflow:hidden;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#00FF00), to(#00FF00));
        background-image: -moz-linear-gradient(#07c160,#07c160);
        background-image: gradient(linear, left top, left bottom, from(#00FF00), to(#00FF00));
        background-image: linear-gradient(#07c160,#07c160);
        // -webkit-animation-timing-function: ease-in-out;
        // -webkit-animation-name: breathe;
        // -webkit-animation-duration: 1000ms;
        // -webkit-animation-iteration-count: infinite;
        // -webkit-animation-direction: alternate;
        // animation:ease-in-out breathe 1000ms infinite alternate;
      }

      @-webkit-keyframes breathe {
        0% { opacity: .2; box-shadow:0 1px 2px rgba(255,255,255,0.1);}
        100% { opacity: 1; border:1px solid rgba(59,235,235,1); box-shadow:0 1px 30px rgba(59,255,255,1);}
      }
      @keyframes breathe {
        0% { opacity: .2; box-shadow:0 1px 2px rgba(255,255,255,0.1);}
        100% { opacity: 1; border:1px solid rgba(59,235,235,1); box-shadow:0 1px 30px rgba(59,255,255,1);}
      }

    }

  //   .date-timer { 放到组件中
  //     position: absolute;
  //     right: 0.35rem;
  //     top: .3rem;
  //     line-height: 0.3rem;
  //     color: #70a1fce7;
  //     font-size: .225rem;
  //     font-family:'electronicFont';
  //     text-align: center;
  //     p{
  //       font-size: .225rem;
  //       display:block;
  //     }
  //     em {
  //       display: block;
  //     }
  //     strong {
  //       font-size: .375rem;
  //       text-align: left;
  //       position: absolute;
  //       left: .15rem;
  //     }

  //   }

    .showBtn{
      position: absolute;
      right: 0rem;
      top: 0rem;
      line-height: 0.3rem;
      color: #fff;
      font-size: .225rem;
      text-align: center;
      width: 2rem;
      height: .3rem;
      background: #f70260d5;
    }
    .hiddenBtn{
      position: absolute;
      right: 0rem;
      top: 0rem;
      line-height: 0.3rem;
      color: rgba(255, 255, 255, 0);
      font-size: .225rem;
      text-align: center;
      width: 2rem;
      height: .3rem;
      background: #00d88900;
    }

  }

  // 主体部分
  .mainbox {
    position: absolute;
    top: 1.25rem;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    min-width: 1024px;
    max-width: 1920px;
    z-index: 100;
    margin: 0 auto;
    padding: 0.125rem 0.125rem 0;
    min-height: 780px;
    background: url(../assets/images/bg.jpg) no-repeat bottom;

    .column {
      flex: 2;
      .panel {
        position: relative;
        width: 100%;
        height: 3.875rem;
        padding: 0 0.1rem 0.3rem;
        border: 1px solid rgba(25, 186, 139, 0.17);
        margin-bottom: 0.1875rem;
        overflow: hidden;

      .profile {
        position: relative;
        width: 100%;
        color: #70a1fce7;
      }
      .com-chart {
        height: 96%;
        z-index: 5;
        overflow: hidden;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 10px;
        border-left: 2px solid #02a6b5;
        border-top: 2px solid #02a6b5;
        content: "";
      }
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 10px;
        border-right: 2px solid #02a6b5;
        border-top: 2px solid #02a6b5;
        content: "";
      }
      .panel-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        &::before {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 10px;
          height: 10px;
          border-left: 2px solid #02a6b5;
          border-bottom: 2px solid #02a6b5;
          content: "";
        }
        &::after {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 10px;
          height: 10px;
          border-right: 2px solid #02a6b5;
          border-bottom: 2px solid #02a6b5;
          content: "";
        }
      }
      .unit {
        // background: rgba(101, 132, 226, 0.3);
        background: rgba(3, 44, 71, 0.3);
        padding: .15rem .325rem;
        margin-top: .25rem;
        height: 88%;
        // height: 3.875rem;
        position: relative;
        width: 100%;
        border: 1px solid rgba(25, 186, 139, 0.17);
        // text-align: center;
        font-size: .25rem;
        line-height: .375rem;
        li{
          padding-top: 0.125rem;
        }
        span{
          text-align: left;
          color:#bceafc
        }
        p{
          padding-left: .5rem;
          color: #fff;
        }
      }
    }
    }
    .column:nth-child(2) {
      flex: 6;
      margin: 0 0.125rem 0.1875rem;
      overflow: hidden;

    .tmap {
        position: relative;
        height: 8rem;
        // color: #fff;

        #map_div{
          width: 96%;
          height: 94%;
          margin: 2% auto;
          position: relative;
          z-index: 0;
        }
        .div_any_child{
          width: 100%;
          height: 100%;
          box-shadow: -10px 0px 15px #034c6a inset,
          0px -10px 15px #034c6a inset,
          10px 0px 15px #034c6a inset,
          0px 10px 15px #034c6a inset;
          border: 1px solid #034c6a;
          box-sizing: border-box;
          position: relative;
          margin-top: 5px;
          z-index: 2;
        }
    }

  }
  }
}
</style>
