import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  // 配置全局数据
  state: {
    uid: '',
    TMapGeoCenter: [30.29591, 120.139749],
    devId: null,
    webData: null,
    detailObj: [],
    isLoginScan: false, // 是否用扫码方式登录
    needReLogin: false, // 是否重复一次登录
    projectNameFromPie: null // 来自pie产生的项目名称，用于过滤筛选设备
  },
  mutations: {
    getUid (state, uid) {
      state.uid = uid
    },
    // 更新地图中的中心点
    newGeoCenter (state, geoCenter) {
      state.TMapGeoCenter = geoCenter
    },
    // 点击饼图 更新表格中的项目名称（表格组件得到新项目名称后过滤 mapData_placed,mapData_alerted,mapData_handled）
    newProjectNameFromPie (state, projectNameFromPie) {
      state.projectNameFromPie = projectNameFromPie
    },
    // 点击table 如果dev_id不相同记录新值，相同改为null 可以使之后点击都为新值
    detailDevId (state, devId) {
      if (state.devId !== devId) {
        state.devId = devId
      } else {
        state.devId = null
      }
    },
    // table 转 详情页
    detailObj (state, detailObj) {
      state.detailObj = detailObj
    },
    // 切换登录卡为扫码
    changeLoginToScan (state, isLoginScan) {
      state.isLoginScan = isLoginScan
    },
    // 通知index页 需要重新调用登录函数
    needReLogin  (state, B) {
      state.needReLogin = B
    }
  },
  actions: {
  },
  modules: {
  }
})
