<template>
  <!-- 头部的盒子 -->
    <div id="head0">
      <!-- <div class="web-name">{{webName}}</div> -->
      <!-- 日期 时间 -->
      <div class="date-timer">
        <p>
          {{today}}
        </p>
        <em>{{weekday}}</em>
        <strong>{{time}}</strong>
      </div>
    </div>
</template>
<script>

export default {
  data () {
    return {
      webName: '',
      today: '',
      weekday: '',
      time: ''
    }
  },
  mounted () {
    // this.webName = this.$store.state.webData.webName
    this.showTime()
  },
  methods: {
    // 函数  右上角显示日期和时间
    showTime () {
      var today = new Date()
      var weekday = new Array(7)
      weekday[0] = '星期日'
      weekday[1] = '星期一'
      weekday[2] = '星期二'
      weekday[3] = '星期三'
      weekday[4] = '星期四'
      weekday[5] = '星期五'
      weekday[6] = '星期六'
      var y = today.getFullYear() + '年'
      var month = today.getMonth() + 1 + '月'
      var td = today.getDate() + '日'
      var d = weekday[today.getDay()]
      var h = today.getHours()
      var m = today.getMinutes()
      var s = today.getSeconds()
      if (h < 10) {
        h = '0' + h
      }
      if (m < 10) {
        m = '0' + m
      }
      if (s < 10) {
        s = '0' + s
      }
      this.today = y + month + td
      this.weekday = d
      this.time = h + ':' + m + ':' + s
      setTimeout(() => {
        this.showTime()
      }, 1000)
    }
  }
}
</script>
<style lang="less" scoped>
// .web-name {
//   position: absolute;
//   right: 9.5rem;
//   top: .8rem;
//   line-height: 0.3rem;
//   color:rgba(8, 184, 190, 0.644);
//   // color: #70a1fce7;
//   font-size: .225rem;
//   font-family:"Microsoft YaHei","黑体","宋体","Arial",sans-serif;
//   // font-family:'electronicFont';
//   // margin: 0 auto;
//   text-align: center;
// }
.date-timer {
  position: absolute;
  right: 0.35rem;
  top: .3rem;
  line-height: 0.3rem;
  color: #70a1fce7;
  font-size: .225rem;
  font-family:'electronicFont';
  text-align: center;
  p{
    font-size: .225rem;
    display:block;
  }
  em {
    display: block;
  }
  strong {
    font-size: .375rem;
    text-align: left;
    position: absolute;
    left: .15rem;
  }

}

</style>
