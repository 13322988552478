<template>
  <div class="com-container">
    <div class="area-text">
    <h3>项目简介</h3>
    <p class="typewriter">{{ typewriter }}</p>
  </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      typewriter: '',
      i: 0,
      timer: 0,
      profile: ''
    }
  },
  mounted () {
    this.profile = this.$store.state.webData.profile
    // this.profile = this.$store.state.webData.webConfig[0].profile
    // console.log('profile------', this.profile)

    // this.getData()
    this.typeing(this.profile)
  },
  methods: {
    typeing (str) {
      if (this.i <= str.length + 300) {
        this.typewriter = str.slice(0, this.i++) + '_'
        this.timer = setTimeout(() => {
          this.typeing(str)
        }, 230)
      } else {
        // clearTimeout(this.timer)
        this.i = 0
        this.typeing(str)
      }
    }
  }
}

</script>
<style scoped lang="less">
.area-text {
  position: relative;
  width: 100%;
  color: #70a1fce7;

  h3{
    color: #70a1fce7;
    margin-top: .125rem;
    margin-bottom: 0;
    font-size: 0.25rem;
  }
  .typewriter{
    font-size: .2rem;
    line-height: .36rem;
  }
}
</style>
