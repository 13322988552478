<template>
  <div class="com-container">
    <h3>图片展示</h3>
    <div class="com-chart">
      <div class="block">
          <el-carousel height="230px" indicator-position="outside">
            <el-carousel-item v-for="(item, index) in imgUrlArr" :key="index" >
              <el-image
                :src="item"
                fit="scale-down"
                :preview-src-list="srcList">
              </el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      webData: null,
      imgUrlArr: [],
      srcList: [],
      currentIndex: 0,
      timer: null
    }
  },
  mounted () {
    this.imgUrlArr = this.$store.state.webData.imgUrlArr
    this.srcList = this.$store.state.webData.imgUrlArr
  },

  methods: {
  }
}
</script>

<style>
.el-carousel__indicators{
  width: 4rem !important;
  margin: 20px auto !important;
  /* margin-left: -0.25rem !important; */
}
  /* .photo{
    height: 460px;
    padding-top: .525rem;
    overflow: hidden;

  } */
  .el-carousel__item {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    /* margin: 0; */
    align-items: center;
    margin-top: .125rem;
  }
  .el-carousel__item:nth-child(2n) {
    background-color: #39a8ed2c;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #39a8ed2c;
  }
</style>
