import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import '@/assets/css/global.less'
import axios from 'axios'
// import GlobalData from '@/components/GlobalData'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 }

Vue.use(ElementUI)

// Vue.prototype.$GD = GlobalData

Vue.prototype.$axios = axios
// Vue.prototype.jsonUrl = 'https://bytube119-1g0lt4yo8ba6465c-1305609995.tcloudbaseapp.com/' // ！生产时 要和上传的目录一致
Vue.prototype.jsonUrl = 'http://localhost:8080'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
