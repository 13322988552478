<template>
  <div class="com-container">
      <!-- 联系方式 -->
        <h3>联系施工单位</h3>
        <ul class="unit">
          <li><span>单位名称:</span>
            <p>{{company}}</p>
            <!-- <p>杭州新建白蚁防治有限公司</p> -->
          </li>
          <li><span>负责人:</span>
            <p>{{name}}</p>
            <!-- <p>程文冲</p> -->
          </li>
          <li><span>联系方式:</span>
            <p>{{phone}}</p>
            <!-- <p>13805700209</p> -->
          </li>
        </ul>
  </div>
</template>
<script>
export default {
  data () {
    return {
      webData: null,
      company: null,
      name: null,
      phone: null
    }
  },
  mounted () {
    this.company = this.$store.state.webData.card.company
    this.name = this.$store.state.webData.card.name
    this.phone = this.$store.state.webData.card.phone
  },
  methods: {

  }
}
</script>
<style lang="less" scoped>
.com-container{
    width: 100%;
    height: 100%;
    // overflow: hidden;
    h3{
        color: #70a1fce7;
        margin-top: 10px ;
        font-size: 0.24rem;
    }
}
</style>
