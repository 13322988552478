import Vue from 'vue'
import VueRouter from 'vue-router'

// import Login from '@/views/Login'
// import GlobalDataPage from '@/views/GlobalDataPage'

import index from '@/views/index'
// import indextest from '@/views/indextest'

// import PiePage from '@/views/PiePage'
// import LinePage from '@/views/LinePage'
// import MapPage from '@/views/MapPage'
// import ProfilePage from '@/views/ProfilePage'
// import PhotoPage from '@/views/PhotoPage'
// import TMapPage from '@/views/TMapPage'
// import TablePage from '@/views/TablePage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: index
  }
  // {
  //   path: '/globaldatapage',
  //   component: GlobalDataPage
  // }

  // {
  //   path: '/globaldatapage',
  //   redirect: '/index'
  // },
  // {
  //   path: '/piepage',
  //   component: PiePage
  // },
  // {
  //   path: '/linepage',
  //   component: LinePage
  // },
  // {
  //   path: '/mappage',
  //   component: MapPage
  // },
  // {
  //   path: '/PhotoPage',
  //   component: PhotoPage
  // },
  // {
  //   path: '/TMapPage',
  //   component: TMapPage
  // },
  // {
  //   path: '/TablePage',
  //   component: TablePage
  // },
  // {
  //   path: '/ProfilePage',
  //   component: ProfilePage
  // }
]

const router = new VueRouter({
  // mode: 'history',hash
  mode: 'hash',
  routes
})

export default router
